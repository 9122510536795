<template>
  <div v-if="shops">
    <CCard color="white" text-color="black" class="text-center" body-wrapper>
      <CRow>
        <CCol md="2"></CCol>
        <CCol md="8">
          <h2 class="font-weight-normal">{{ $t('create') }} {{ $t('preVoucher') }}</h2>
        </CCol>
        <CCol md="2" class="text-right">
          <a @click="$router.go(-1)">
            <CButton>
              <CIcon name="cil-arrow-thick-from-right" />
              {{ $t('back') }}
            </CButton>
          </a>
        </CCol>
      </CRow>
      <hr />
      <br />

      <p class="text-left text-danger font-weight-normal">{{ alertMessage }}</p>

      <CRow>
        <CCol md="6">
          <p class="text-left font-weight-normal">
            {{ $t('generalDetail') }}
            <span class="text-danger font-weight-normal">*</span>
          </p>
          <hr />

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark">
                  {{ $t('img') }}
                  <span class="text-danger font-weight-normal">*</span>
                </label>
                <div class="col-sm-9">
                  <CJumbotron v-if="url" color="white" border-color="grey-200" class="pointer">
                    <img alt="" :src="url" width="50%" />
                  </CJumbotron>
                  <CJumbotron color="white" border-color="grey-200" v-else class="pointer">
                    <div class="custom-file mb-3">
                      <input style="border-color:white" type="file" class="text-black form-control pointer" required
                        @change="onFileSelect" />
                      <br />
                      <label class="custom-file text-left text-description" style="font-size:12px">
                        {{ $t('selectedFile') }} ...
                        <span class="text-info">
                          {{ $t('fileSize') }}
                        </span>
                      </label>
                    </div>
                  </CJumbotron>
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-dark">
                  {{ $t('voucherName') }} <label class="text-danger">*</label>
                </label>
                <div class="col-sm-9">
                  <CInput v-model="title" :placeholder="$t('inputVoucherDisplayName') + ' ...'" />
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-dark">
                  {{ $t('voucherValue') }} <label class="text-danger">* </label>
                </label>
                <div class="col-sm-9">
                  <CInput type="number" v-model.number="value" :placeholder="$t('inputVoucherValue') + ' ...'" />
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-dark">
                  {{ $t('voucherAmount') }} <label class="text-danger">* </label>
                </label>
                <div class="col-sm-9">
                  <CInput type="number" v-model.number="total" :placeholder="$t('voucherAmount') + ' ...'"
                    @input="validateTotal" />
                  <h6 :class="classText" style="font-size:10px">
                    {{ $t('limit1000Voucher') }}
                  </h6>
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-dark">
                  {{ $t('condition') }}
                </label>
                <div class="col-sm-9">
                  <CTextarea class="text-black" v-model="remark" :placeholder="$t('voucherDetailPlaceholder')" rows="3" />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>

        <CCol md="6">
          <p class="text-left font-weight-normal">
            {{ $t('voucherExpire') }}
          </p>
          <hr />
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-grey pointer">
                  <input type="checkbox" v-model="isExpiration" true-value="true" false-value="false" />
                  {{ $t('setPeriod') }}
                </label>
                <div class="col-sm-9">
                  <input v-if="isExpiration === 'false' || isExpiration === ''" class="text-black form-control"
                    disabled />
                    <v-md-date-range-picker v-else opens="center" :presets="null" :currentLang="getCurrentLang()"
                :locale="getCurrentLang()" @change="handleChangeDate" style="width: 100%;">
                <template slot="input" class="activator-wrapper">
                  <input type="text" readonly="readonly" style="background-color: white;"
                    class="form-control input-group-text" :value="dateText" :placeholder="$t('selectDate')" />
                </template>
              </v-md-date-range-picker>
                  <!-- <date-picker v-else v-model="date" type="date" input-class="form-control" @change="handleChange"
                    :disabled-date="notBeforeToday" style="width:100%" range>
                  </date-picker> -->
                  <h6 class="text-description text-left" style="font-size:10px;padding-top:8px">
                    {{ $t('incaseNoUsagePeriod') }}
                  </h6>
                </div>
              </div>
            </CCol>
          </CRow>
          <p class="text-left font-weight-normal">
            Run Number (No.)
            <span class="text-danger font-weight-normal">*</span>
          </p>
          <hr />
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-grey font-weight-normal">
                  {{ $t('creationOption') }} <strong class="text-danger"> *</strong>
                </label>
                <label class="col-sm-4 col-form-label text-left text-grey pointer">
                  <input type="radio" v-model="isVoucherNO" value="true" />
                  {{ $t('createBySystemDefault') }}
                </label>
                <label class="col-sm-4 col-form-label text-left text-grey pointer">
                  <input type="radio" v-model="isVoucherNO" value="false" />
                  {{ $t('custom') }}
                </label>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-grey">
                  <span>
                    {{ $t('code') }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <input class="form-control" v-if="isVoucherNO === 'false'" v-model="character"
                    :placeholder="$t('codePlaceholder')" />
                  <input class="form-control" v-else disabled v-model="character" :placeholder="$t('codePlaceholder')" />
                  <h6 class="text-description text-left" style="font-size:10px;padding-top:8px">
                    {{ $t('incaseNoCodeDialog') }}
                  </h6>
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 text-left text-grey">
                  {{ $t('startingNumber') }}
                </label>

                <div class="col-sm-9">
                  <input class="form-control" type="number" v-model="sequential"
                    :placeholder="$t('startingNumberPlaceholder')" onKeyPress="if(this.value.length==6) return false;"
                    v-if="isVoucherNO === 'false'" />
                  <input class="form-control" v-else disabled type="number" v-model.number="sequential"
                    :placeholder="$t('startingNumberPlaceholder')" />
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <CCardBody color="light">
                <p class="text-left">
                  {{ $t('example') }} Voucher NO.
                </p>
                <h6 class="text-grey" style="display:inline;padding-left:1em;padding-right:1em"
                  v-for="serial in generateVoucherNO()" :key="serial">
                  {{ serial }}
                </h6>
              </CCardBody>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <br />
      <CButton block color="success" @click="saveVoucher()" v-if="loadingButton === true">
        {{ $t('createVoucher') }}
      </CButton>
      <CButton block color="success" class="px-4" disabled v-else>
        <CSpinner color="light" size="sm" /> {{ $t('createVoucher') }}
      </CButton>
    </CCard>

    <CModal :show.sync="imgModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center">
        <CCard color="white" text-color="dark" class="text-center col-md-12" body-wrapper>
          <CCardBody color="light">
            <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" alt="Source Image">
            </VueCropper>
          </CCardBody>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12">
          <CCol md="4" v-if="loadingButton === true">
            <CButton block color="success" @click="editImg()">
              {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingButton === false">
            <CButton block color="success" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="imgModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import moment from 'moment'
import util from '@/util/util'
import crm from '@/services/crm'
import pos from '@/services/pos'
import NoShop from '@/containers/NoShop'

export default {
  components: { VueCropper, DatePicker, NoShop },
  data() {
    return {
      imgModal: false,
      selectedFile: '',
      cropedImage: '',
      loadingButton: true,
      url: '',
      title: '',
      value: null,
      total: 1,
      remark: '',
      isExpiration: '',
      date: [new Date(), new Date()],
      sequential: '',
      isVoucherNO: '',
      character: '',
      startAt: '',
      endAt: '',
      alertMessage: '',
      classText: 'text-description text-left',
      voucherObjectId: '',
      voucher: {},
      voucherNOType: '',
      footer: '',
      dateText: null,
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    serialNumber() {
      return util.generateSerialNumber()
    },
  },
  methods: {
    ...util,
    getCurrentLang() {
      return this.$i18n.locale
    },
    handleChangeDate(values) {
      const date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.dateText = moment(values[0])
        .locale('en')
        .format('DD/MM/YYYY') + " - " +
        moment(values[1])
          .locale('en')
          .format('DD/MM/YYYY')
      this.startAt = date.start
      this.endAt = date.end
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    editImg() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.uploadImage(this.cropedImage)
    },
    onFileSelect(e) {
      const file = e.target.files[0]

      this.imgModal = true

      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    handleImage(e) {
      const file = e.target.files[0]
      this.createBase64Img(file)
    },
    createBase64Img(fileObject) {
      this.url = URL.createObjectURL(fileObject)
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
        this.uploadImage(this.image)
      }

      reader.readAsDataURL(fileObject)
    },
    uploadImage(image) {
      let data = {
        image: image,
      }

      this.loadingButton = false

      pos
        .post('/images/v1.0/upload/general/' + this.uid, data)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            let baseUrl = res.data.data.baseUrl
            let url = res.data.data.url

            this.imgUrl = baseUrl + url
            this.url = this.imgUrl
            this.imgModal = false
            this.loadingButton = true
          } else {
            //มีบางอย่างผิดพลาด
            console.log(res.data.error.message)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    validateIsExpiration() {
      if (this.isExpiration === 'true' && this.startAt === '') {
        this.startAt = moment(new Date()).toISOString()
        this.endAt = moment(new Date()).toISOString()
      } else if (this.isExpiration === 'false') {
        this.startAt = ''
        this.endAt = ''
      }
    },
    validateTotal(value) {
      if (value > 1000) {
        this.classText = 'text-danger text-left font-weight-normal'
      } else {
        this.classText = 'text-mute text-left font-weight-normal'
      }
    },
    validateData() {
      if (
        this.title === '' ||
        this.value === 0 ||
        this.total > 1000 ||
        this.voucherNOType === ''
      ) {
        this.alertMessage = this.$i18n.t('errorAlert')
      } else {
        this.alertMessage = ''
      }

      return this.alertMessage === ''
    },
    reset() {
      this.sequential = ''
      this.character = ''
    },
    generateVoucherNO() {
      let voucherNO = []

      if (this.isVoucherNO === 'true') {
        this.reset()

        for (let i = 0; i < 3; i++) {
          let num = String(i + 1).padStart(7, '0')
          voucherNO.push(num)
          this.voucherNOType = 0
        }
      } else if (this.isVoucherNO === 'false') {
        if (this.sequential !== '') {
          for (let i = 0; i < 3; i++) {
            let num = String(Number(this.sequential) + i).padStart(7, '0')
            voucherNO.push(this.character + num)
            this.voucherNOType = 1
          }
        } else if (this.sequential === '') {
          this.voucherNOType = ''
        }
      }
      return voucherNO
    },
    handleChange(values) {
      let date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.startAt = date.start
      this.endAt = date.end
    },
    pushVoucher() {
      let datetime = new Date()
      let voucherNO = ''
      let prevoucherdata = []
      let voucherCode = ''

      for (let i = 0; i < this.total; i++) {
        if (this.voucherNOType === 0) {
          voucherNO = String(i + 1).padStart(7, '0')
        } else if (this.voucherNOType === 1) {
          let num = String(Number(this.sequential) + i).padStart(7, '0')
          voucherNO = this.character + num
        }

        voucherCode = util.generateVoucherCode()

        prevoucherdata.push({
          id: util.createObjectId(),
          objectId: util.createObjectId(),
          uid: this.uid,
          title: this.title,
          value: this.value,
          startAt: this.startAt,
          endAt: this.endAt,
          isUsed: false,
          remark: this.remark,
          voucherNO: voucherNO,
          voucherCode: voucherCode,
          created_at: datetime,
          updated_at: datetime,
          voucherObjectId: this.voucherObjectId,
          voucher: this.voucher,
          type: 1,
        })
      }

      return prevoucherdata
    },
    createPreVoucher() {
      let data = this.pushVoucher()

      crm
        .post('/api/v1.0/' + this.uid + '/voucher/generateprevoucher', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.loadingButton = true
            this.$router.push('/voucher#pre-voucher')
          } else {
            alert(this.$i18n.t('errorAlert'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    saveVoucher() {
      this.loadingButton = false
      this.validateIsExpiration()
      let validate = this.validateData()

      const uid = this.uid
      const users = this.users

      let createdBy = {
        shopName: users.shopName,
        currentPlan: users.currentPlan,
      }

      let data = {
        uid: uid,
        imgUrl: this.imgUrl,
        title: this.title,
        description: this.remark,
        value: this.value,
        startAt: this.startAt,
        endAt: this.endAt,
        expirationAt: '',
        createdBy: createdBy,
        updatedBy: createdBy,
        total: this.total,
        serialNumber: this.serialNumber,
        type: 1, //pre-voucher
      }

      if (validate) {
        crm
          .post('/api/v1.0/' + uid + '/voucher/save', data)
          .then((res) => {
            if (res.data.error.code === 0) {
              this.voucherObjectId = res.data.data.objectId[0]

              this.voucher = {
                objectId: this.voucherObjectId,
                title: this.title,
                value: this.value,
                serialNumber: this.serialNumber,
              }

              this.createPreVoucher()
            } else {
              alert(this.$i18n.t('errorAlert'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
